@import 'variables';

$prefix: $antPrefix + -btn;
$notClasses: '&:not(.#{$prefix}-primary):not(.#{$prefix}-add):not(.#{$prefix}-danger):not(.#{$prefix}-dangerous):not(.#{$prefix}-link):not(.#{$antPrefix}-dropdown-trigger)';
$ghostClass: '.#{$prefix}-background-ghost';

%disabledStyle {
  background-color: $bodyBackground;
  color: $disabledColor;

  &:hover {
    background-color: $bodyBackground;
    color: $disabledColor;
  }
}

%primaryButton {
  &:not([disabled]) {
    &:not(#{$ghostClass}) {
      background-color: $primaryColor;

      &:hover {
        color: $primaryColor;
        background-color: transparent;
        text-shadow: none;
      }
    }

    border-color: $primaryColor;

    &:hover {
      color: $primaryColor;
      background-color: transparent;
    }

    &#{$ghostClass} {
      color: $primaryColor;

      &:hover {
        color: $primaryHover;
      }
    }
  }

  &:disabled {
    border-color: $borderColor;

    &:hover {
      border-color: $disabledColor;
    }

    &:not(#{$ghostClass}) {
      @extend %disabledStyle;
    }
  }
}

%addButton {
  &:not([disabled]) {
    &:not(#{$ghostClass}) {
      color: #fff;
      background-color: $addColor;

      &:hover {
        color: $addColor;
        background-color: transparent;
        text-shadow: none;
      }
    }

    border-color: $addColor;
    display: flex;
    align-items: center;

    &:hover {
      color: $addColor;
      background-color: transparent;
    }

    &#{$ghostClass} {
      color: $addColor;

      &:hover {
        color: $primaryHover;
      }
    }
  }

  &:disabled {
    border-color: $borderColor;

    &:hover {
      border-color: $disabledColor;
    }

    &:not(#{$ghostClass}) {
      @extend %disabledStyle;
    }
  }
}

%defaultButton {
  &:not([disabled]) {
    background-color: $componentBackground;
    color: $textColor;
    border-color: $borderColor;

    &:hover {
      color: $primaryColor;
      border-color: $primaryColor;
    }
  }

  &:disabled {
    color: $disabledColor;
    background-color: $bodyBackground;
    border-color: $borderColor;
  }
}

%linkButton {
  &:not(disabled) {
    color: $primaryColor;
    border: none;

    &:hover {
      color: $primaryColor;
      opacity: 0.8;
    }

    &#{$ghostClass}:hover {
      color: $itemHoverColor;
    }
  }

  &:disabled {
    color: $disabledColor;

    &:hover {
      color: $disabledColor;
    }
  }

  span+.anticon {
    margin-left: 8px;
  }
}

%dangerButton {
  &:not(disabled) {
    background-color: $secondaryColor;
    color: $secondaryHover;
    border-color: $secondaryColor;

    &.ant-btn-link,
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: $secondaryColor;
      border-color: $secondaryColor;
      opacity: 1;
    }

    &.ant-btn-link:hover,
    &.ant-btn-link:focus,
    &.ant-btn-link:active {
      background-color: $secondaryColor;
      color: $secondaryHover;
      border-color: $secondaryColor;
    }
  }

  &:disabled {
    @extend %disabledStyle;
    border-color: $borderColor;

    &:hover {
      border-color: $borderColor;
    }
  }

  &.#{$prefix}-link:disabled {
    color: $disabledColor;

    &:hover {
      color: $disabledColor;
    }
  }

  &.#{$prefix}-background-ghost {
    border-color: $secondaryColor;
    color: $secondaryColor;

    &:hover,
    &:focus,
    &:active {
      border-color: $secondaryColor;
      color: $secondaryColor;
    }
  }
}

%ghostButton {
  &:not(disabled) {

    &.#{$prefix}-primary:hover,
    &.#{$prefix}-primary:focus {
      border-color: $primaryColor;
    }
  }

  &:disabled {
    color: $disabledColor;

    &:hover {
      color: $disabledColor;
    }
  }
}

.#{$prefix} {
  &:not(.#{$prefix}-circle) {
    border-radius: 3px;
  }

  &::before {
    background: $bodyBackground;
  }

  &>svg {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &>svg+span,
  &>span+svg {
    margin-left: 8px;
  }

  // #{$notClasses} {
  //   @extend %defaultButton;
  // }
  &-lg {
    font-size: 12px;
  }

  &-sm {
    line-height: 100%;
  }
}

.#{$prefix}-default {
  @extend %defaultButton;
}

.#{$prefix}-primary {
  @extend %primaryButton;
}

.#{$prefix}-add {
  @extend %addButton;
}

.#{$prefix}-link {
  @extend %linkButton;
}

.#{$prefix}-dangerous {
  @extend %dangerButton;
}

.#{$prefix}-background-ghost {
  @extend %ghostButton;
}

.#{$prefix}-dashed {
  &:hover {
    color: $primaryColor;
    border-color: $primaryColor;
  }
}