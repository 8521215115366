@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;

  &-header,
  &-sider,
  &-sider-children,
  &-sider-trigger {
    background: $clientColor;
  }
}

.login-layout {
  min-height: 100vh;
  display: inherit;
  background: url('../../../images/login-bg.png');
  background-size: cover;

  .login-form-col {
    background-color: $componentBackground;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    form {
      width: 90%;
    }
  }
}