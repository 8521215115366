@import 'variables';

$prefix: $antPrefix + -input;

%inputBaseStyle {
  color: $textColor;
  background-color: $componentBackground;
  border-color: $borderColor;

  &-lg {
    font-size: 12px;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &-focused {
    border-color: $primaryColor;
  }
}

%disabledStyle {
  color: $disabledColor;
  background-color: $bodyBackground;
  border-color: $borderColor;

  &:hover {
    border-color: $borderColor;
  }
}

%errorStyle {

  .#{$prefix},
  .#{$prefix}-affix-wrapper,
  .#{$prefix}:hover,
  .#{$prefix}-affix-wrapper:hover {
    background-color: $componentBackground;
  }
}

%placeholderColor {
  &::placeholder {
    color: $disabledColorA04;
  }
}

.#{$prefix},
.#{$prefix}-number,
.#{$prefix}-number-input {
  @extend %inputBaseStyle;
}

.#{$prefix}-number-input {
  text-align: right;
  padding-right: 24px;
}

.#{$prefix}-number-handler,
.#{$prefix}-number-handler-up-inner,
.#{$prefix}-number-handler-down-inner {
  color: $textColor;
  background-color: $componentBackground;
}

.#{$prefix}-affix-wrapper:not(.#{$prefix}-affix-wrapper-disabled):hover {
  border-color: $primaryColor;
}

.#{$prefix}-affix-wrapper {
  min-height: 32px;

  &:not(.#{$prefix}-affix-wrapper-disabled) {
    @extend %inputBaseStyle;
  }

  &-disabled {
    @extend %disabledStyle;

    .#{$prefix}-search-icon,
    .#{$prefix}-clear-icon {
      cursor: not-allowed;
      color: $disabledColor;

      &:hover {
        color: $disabledColor;
      }
    }

    input:disabled {
      background-color: $bodyBackground;
      @extend %placeholderColor;
    }
  }
}

.#{$prefix}-search-icon,
.#{$prefix}-password-icon,
.#{$prefix}-clear-icon {
  color: $textColor;

  &:hover {
    color: $primaryColor;
  }

  &::before {
    border-left-color: $borderColor;
  }
}

.#{$prefix}[disabled] {
  @extend %disabledStyle;
  @extend %placeholderColor;
}

.#{$antPrefix}-form-item-has-error {
  @extend %errorStyle;
}

.has-error {
  .#{$prefix} {
    border-color: $errorColor;

    &:hover {
      border-color: $error100;
    }

    &-affix-wrapper {
      color: $errorColor;

      i {
        color: $errorColor;
      }

      &:hover,
      &:focus-within {
        .#{$prefix}:not(.#{$prefix}-disabled) {
          border-color: $errorColor;
        }
      }
    }
  }
}

.#{$prefix}-search {
  >.#{$prefix}-group>.#{$prefix}-group-addon:last-child .#{$prefix}-search-button:not(.#{$antPrefix}-btn-primary) {
    color: $disabledColor;

    &:hover {
      color: $primaryColor;
      border-color: $primaryColor;
    }
  }
}

.#{$prefix}-group.#{$prefix}-group-compact {
  display: flex;
}