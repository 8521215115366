@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  &-header,
  &-sider,
  &-sider-children,
  &-sider-trigger {
    background: white;
    opacity: 0.6;
  }

  &-content {
    border-radius: 0%;
  }

  .#{$prefix} {
    background: transparent;
  }
}