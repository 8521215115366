body.user {
  @import '~antd/dist/reset.css';
  @import 'button';
  @import 'card';
  @import 'checkbox';
  @import 'collapse';
  @import 'descriptions';
  @import 'divider';
  @import 'form';
  @import 'input';
  @import 'layout';
  @import 'list';
  @import 'modal';
  @import 'notifications';
  @import 'pagination';
  @import 'picker';
  @import 'popover';
  @import 'progress';
  @import 'radio';
  @import 'result';
  @import 'select';
  @import 'spin';
  @import 'steps';
  @import 'switch';
  @import 'table';
  @import 'tabs';
  @import 'tag';
  @import 'time-picker';
  @import 'tree';
  @import 'menu';
  @import 'dropdown';
  @import 'typography';
  @import 'empty';
  @import 'pageheader';
  @import 'upload';
  @import 'skeleton';
  @import 'scrollbar';
  @import 'linkedin';
  @import 'alert';
  @import 'font';
  @import 'mentions';
  @import 'workflow-graph';
  @import 'workflow-form';
}