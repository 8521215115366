@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;

  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }

  &-head {
    border-bottom-color: $borderColor;
  }

  &-bordered {
    border-color: $borderColor;
  }
}

.#{$prefix}.widget-card {
    width: 150px;
    height: 150px;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;

  .#{$prefix}-body {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;

    .widget-image-div {
      position: relative;
    }

    .widget-ellipse {
      position: absolute;
      top: 16px;
      z-index: 1;
    }

    .widget-image {
      position: relative;
      z-index: 2;
    }
  }
}