@import 'variables';

$prefix: $antPrefix + -menu;
$darkClass: $prefix + -dark;

$activeArrowClass: '.#{$prefix}-item:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow';
$activeLinkClass: '.#{$prefix}-item:hover,.#{$prefix}-item-active,.#{$prefix}-submenu-active,.#{$prefix}-submenu-open,.#{$prefix}-submenu-selected,.#{$prefix}-submenu-title:hover';
$menuBorderRight: '.#{$prefix}-vertical .#{$prefix}-item::after, .#{$prefix}-vertical-left .#{$prefix}-item::after, .#{$prefix}-vertical-right .#{$prefix}-item::after, .#{$prefix}-inline .#{$prefix}-item::after';

@mixin selectedItemStyle($prefixClass) {
  &.#{$prefixClass}:not(.#{$prefix}-horizontal) .#{$prefix}-item-selected {
    color: $primaryColor;
    background-color: $itemHoverBackground;
  }

  &.#{$prefixClass} .#{$prefix}-item-selected {

    &>a,
    &>a:hover,
    &>a:focus {
      color: $primaryColor;
    }
  }
}

%baseMenu {
  #{$activeLinkClass} {
    &>a {
      color: $primaryColor;
    }
  }

  color: $textColor;
  background: $componentBackground;
}

%lightMenu {
  @extend %baseMenu;

  .#{$prefix}-item:hover,
  .#{$prefix}-item,
  .#{$prefix}-item-active,
  .#{$prefix}:not(.#{$prefix}-inline) .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-title:hover {
    color: $primaryColor;
  }

  .#{$prefix}-item:active,
  .#{$prefix}-submenu-title:active {
    background-color: transparent;
  }
}

.#{$prefix}-sub.#{$prefix}-inline>.#{$prefix}-item {
  color: $textColor;

  &.#{$prefix}-item-active {
    color: $primaryColor;
  }
}

.#{$prefix}-inline,
.#{$prefix}-vertical,
.#{$prefix}-light.#{$prefix}-root.#{$prefix}-inline {
  border-right: transparent;
  border-inline-end: none;
}

.#{$prefix}-item a {
  color: $textColor;
}

.#{$prefix}-item a:hover,
.#{$prefix}-item a:focus {
  color: $primaryColor;
}

.#{$prefix} {
  @extend %lightMenu;
  @include selectedItemStyle($prefix);
}

.#{$prefix}-submenu>.#{$prefix} {
  color: $textColor;
  background: $contrastBackground;
}

#{$menuBorderRight} {
  border-color: $primaryColor;
}

.#{$prefix}-item-selected {
  color: $primaryColor;
}

.#{$prefix}-submenu-inline>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow {

  &::before,
  &::after {
    background-image: linear-gradient(to right, $textColor, $textColor);
  }
}

.#{$prefix}-submenu-selected>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu-open>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu>.#{$prefix}-submenu-title:hover .#{$prefix}-submenu-arrow {

  &::before,
  &::after {
    background-image: linear-gradient(to right, $primaryColor, $primaryColor);
  }
}

.#{$antPrefix}-tooltip.#{$prefix}-inline-collapsed-tooltip.#{$antPrefix}-tooltip-placement-right {
  .#{$antPrefix}-tooltip-content {
    .#{$antPrefix}-tooltip-inner {
      a {
        .anticon {
          margin-right: 6px;
        }
      }
    }
  }
}

.#{$darkClass} {
  @extend %baseMenu;

  &.#{$prefix} {
    background: $componentBackground;
  }

  .#{$prefix}-inline.#{$prefix}-sub,
  &.#{$prefix}-sub {
    background: transparent;
  }

  .#{$prefix}-item:hover,
  .#{$prefix}-item-active,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-selected,
  .#{$prefix}-submenu-title:hover {
    color: $primaryColor;
  }

  @include selectedItemStyle($darkClass);
}